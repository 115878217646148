import {mapMutations} from "vuex";
import { Carousel, Slide } from 'vue-carousel';
export default {
  name: "promo",
  components:{
    Carousel,
    Slide
  },
  data(){
    return{

    }
  },
  created() {
  },
  computed:{

  },
  methods:{
    ...mapMutations({
    }),
    carouselNav($event, direction) {
      const carousel = this.$refs['carousel'];
      carousel.advancePage(direction);
    }
  }
}