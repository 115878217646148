import {mapActions, mapGetters} from "vuex";
import {Carousel, Slide} from 'vue-carousel';
import Vue from 'vue'


export default {
  name: "tech",
  components: {
    Carousel,
    Slide
  },
  data() {
    return {}
  },
  watch: {},
  created() {
    this.fetchTech()
    this.fetchSpec()
  },
  computed: {
    ...mapGetters({
      tech: 'home/tech',
      techLoading: 'home/techLoading',
      spec: 'home/spec',
      specLoading: 'home/specLoading',
    }),

  },
  methods: {
    ...mapActions({
      fetchTech: 'home/GET_TECH',
      fetchSpec: 'home/GET_SPEC'
    }),
    carouselNav($event, direction) {
      const carousel = this.$refs['tech'];
      carousel.advancePage(direction);
    },
  }
}