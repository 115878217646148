import Vue from 'vue'
import {mapActions, mapGetters, mapMutations} from "vuex";
import Vivus from 'vivus';
import {Carousel, Slide} from 'vue-carousel';
import { ObserveVisibility } from 'vue-observe-visibility'
Vue.directive('observe-visibility', ObserveVisibility)

export default {
  name: "specifications",
  components:{
    Carousel,
    Slide
  },
  data(){
    return{

    }
  },
  watch: {

  },
  created() {
    this.fetchCity()
  },
  mounted() {

  },
  computed:{
    ...mapGetters({
      city: 'home/city',
      cityLoading: 'home/cityLoading',
    })
  },
  methods:{
    ...mapActions({
      fetchCity: 'home/GET_CITY',
    }),
    visibilityChanged (isVisible, entry) {
      this.isVisible = isVisible
      if(entry.isIntersecting === true){
        new Vivus('sign', {duration: 200});
      }
    },
    carouselNav($event, direction) {
      const carousel = this.$refs['specifications'];
      carousel.advancePage(direction);
    },
  }
}