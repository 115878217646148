import {mapMutations} from "vuex";
import {Carousel, Slide} from 'vue-carousel';
import Vue from 'vue'
import Vivus from 'vivus';
import {ObserveVisibility} from 'vue-observe-visibility'
import CoolLightBox from 'vue-cool-lightbox'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'

Vue.directive('observe-visibility', ObserveVisibility)

export default {
  name: "interiors",
  components: {
    Carousel,
    Slide,
    CoolLightBox
  },
  data() {
    return {
      imageIndex: null,
      list: [
        {
          id: 1,
          title: this.$t('minimalTitle'),
          description: this.$t('minimalText'),
          images: [
            {
              id: 1,
              image: 'https://api.f7-lublin.pl/storage/interiors/minimal/minimal-7.jpg'
            },
            {
              id: 2,
              image: 'https://api.f7-lublin.pl/storage/interiors/minimal/minimal-2.jpg'
            },
            {
              id: 3,
              image: 'https://api.f7-lublin.pl/storage/interiors/minimal/minimal-3.jpg'
            },
            {
              id: 4,
              image: 'https://api.f7-lublin.pl/storage/interiors/minimal/minimal-4.jpg'
            },
            {
              id: 5,
              image: 'https://api.f7-lublin.pl/storage/interiors/minimal/minimal-5.jpg'
            },
            {
              id: 6,
              image: 'https://api.f7-lublin.pl/storage/interiors/minimal/minimal-1.jpg'
            },
            {
              id: 7,
              image: 'https://api.f7-lublin.pl/storage/interiors/minimal/minimal-6.jpg'
            },
            {
              id: 8,
              image: 'https://api.f7-lublin.pl/storage/interiors/minimal/minimal-8.jpg'
            },
            {
              id: 9,
              image: 'https://api.f7-lublin.pl/storage/interiors/minimal/minimal-9.jpg'
            },
            {
              id: 10,
              image: 'https://api.f7-lublin.pl/storage/interiors/minimal/minimal-10.jpg'
            },
            {
              id: 11,
              image: 'https://api.f7-lublin.pl/storage/interiors/minimal/minimal-11.jpg'
            },
            {
              id: 12,
              image: 'https://api.f7-lublin.pl/storage/interiors/minimal/minimal-12.jpg'
            },
            {
              id: 13,
              image: 'https://api.f7-lublin.pl/storage/interiors/minimal/minimal-13.jpg'
            },
            {
              id: 14,
              image: 'https://api.f7-lublin.pl/storage/interiors/minimal/minimal-14.jpg'
            },
            {
              id: 15,
              image: 'https://api.f7-lublin.pl/storage/interiors/minimal/minimal-15.jpg'
            },
            {
              id: 16,
              image: 'https://api.f7-lublin.pl/storage/interiors/minimal/minimal-16.jpg'
            },

          ]
        },
        {
          id: 2,
          title: this.$t('ecleticTitle'),
          description: this.$t('ecleticText'),
          images: [
            {
              id: 1,
              image: 'https://api.f7-lublin.pl/storage/interiors/eclectic/eclectic-1.jpg'
            },
            {
              id: 2,
              image: 'https://api.f7-lublin.pl/storage/interiors/eclectic/eclectic-2.jpg'
            },
            {
              id: 3,
              image: 'https://api.f7-lublin.pl/storage/interiors/eclectic/eclectic-3.jpg'
            },
            {
              id: 4,
              image: 'https://api.f7-lublin.pl/storage/interiors/eclectic/eclectic-4.jpg'
            },
            {
              id: 5,
              image: 'https://api.f7-lublin.pl/storage/interiors/eclectic/eclectic-5.jpg'
            },
            {
              id: 6,
              image: 'https://api.f7-lublin.pl/storage/interiors/eclectic/eclectic-6.jpg'
            },
            {
              id: 7,
              image: 'https://api.f7-lublin.pl/storage/interiors/eclectic/eclectic-7.jpg'
            },
            {
              id: 8,
              image: 'https://api.f7-lublin.pl/storage/interiors/eclectic/eclectic-8.jpg'
            },
            {
              id: 9,
              image: 'https://api.f7-lublin.pl/storage/interiors/eclectic/eclectic-9.jpg'
            },
            {
              id: 10,
              image: 'https://api.f7-lublin.pl/storage/interiors/eclectic/eclectic-10.jpg'
            },
            {
              id: 11,
              image: 'https://api.f7-lublin.pl/storage/interiors/eclectic/eclectic-11.jpg'
            },
            {
              id: 12,
              image: 'https://api.f7-lublin.pl/storage/interiors/eclectic/eclectic-12.jpg'
            },
            {
              id: 13,
              image: 'https://api.f7-lublin.pl/storage/interiors/eclectic/eclectic-13.jpg'
            },
            {
              id: 14,
              image: 'https://api.f7-lublin.pl/storage/interiors/eclectic/eclectic-14.jpg'
            },
            {
              id: 15,
              image: 'https://api.f7-lublin.pl/storage/interiors/eclectic/eclectic-15.jpg'
            },
            {
              id: 16,
              image: 'https://api.f7-lublin.pl/storage/interiors/eclectic/eclectic-16.jpg'
            },
          ]
        },
      ],
      currentImages: [],
    }
  },
  created() {
    this.currentImages = this.list[0]
    // console.log(this.$store.urlPrefix);
  },
  watch: {
    '$store.getters.translation': {
      handler(newVal) {
        this.list[0].title = newVal.minimalTitle
        this.list[0].description = newVal.minimalText
        this.list[1].title = newVal.ecleticTitle
        this.list[1].description = newVal.ecleticText
      }
    },
  },
  computed: {
    interiorsImages(){
      if(!this.currentImages){
        return []
      }
      if(!this.currentImages.images.length){
        return []
      } else {
        return this.currentImages.images.map(el => {
          let {image} = el
          return image
        })
      }
    }
  },
  methods: {
    ...mapMutations({}),
    carouselNav($event, direction) {
      const carousel = this.$refs['interiors'];
      carousel.advancePage(direction);
    },
    setFirstImage() {
      const carousel = this.$refs['interiors'];
      carousel.goToPage(0);
    },
    visibilityChanged(isVisible, entry) {
      this.isVisible = isVisible
      if (entry.isIntersecting === true) {
        new Vivus('inter', {duration: 200});
      }
    }
  }
}