import {mapMutations} from "vuex";

//sections
import promoSection from '@/modules/home/section/promo/index.vue';
import aboutSection from '@/modules/home/section/about/index.vue';
import specificationSection from '@/modules/home/section/specifications/index.vue';
import locationSection from '@/modules/home/section/location/index.vue';
import planningSection from '@/modules/home/section/planning/index.vue';
import interiorsSection from '@/modules/home/section/interiors/index.vue';
import servicesSection from '@/modules/home/section/services/index.vue';
import mediaSection from '@/modules/home/section/media/index.vue';
import contactsSection from '@/modules/home/section/contacts/index.vue';
import tech from "@/modules/home/section/tech/index.vue";
import team from "@/modules/home/section/team/index.vue";

export default {
  name: "home",
  components:{
    promoSection,
    aboutSection,
    specificationSection,
    locationSection,
    planningSection,
    interiorsSection,
    servicesSection,
    mediaSection,
    contactsSection,
    tech,
    team,
  },
  data(){
    return{

    }
  },
  created() {
  },
  computed:{

  },

  methods:{
    ...mapMutations({
    })
  }
}