import {mapGetters, mapMutations} from "vuex";
import Vue from 'vue'
import Vivus from 'vivus';
import { ObserveVisibility } from 'vue-observe-visibility'
Vue.directive('observe-visibility', ObserveVisibility)
import CoolLightBox from 'vue-cool-lightbox'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'


export default {
  name: "planning",
  components:{
    CoolLightBox
  },
  data(){
    return {
      entrance: false,
      imageIndex: null,
      toggleView: false,
      selectedFloor: null,
      floorList: [
        {
          id: 1,
        },
        {
          id: 2,
        },
        {
          id: 3,
        },
        {
          id: 4,
        },
        {
          id: 5,
        },
      ]
    }
  },
  created() {
  },
  computed:{
    ...mapGetters({
      variables: 'setting/variables'
    }),
  },
  methods:{
    ...mapMutations({
    }),
    getVariable(key) {
      return this.variables ? this.variables.find(el => el.key === key) ? this.variables.find(el => el.key === key).value : null : null
    },
    visibilityChanged (isVisible, entry) {
      this.isVisible = isVisible
      if(entry.isIntersecting === true){
        new Vivus('plan', {duration: 200});
      }
    },
    visibilityChangedMask(isVisible, entry){
      (isVisible) ? this.entrance = true : this.entrance = false
    },
    openFloor(id){
      this.toggleView = true
      this.selectedFloor = this.floorList.find(item => item.id === id)
      this.scrollBack()
    },
    closeFloor(){
      this.toggleView = false
      this.selectedFloor = null
      this.scrollBack()
    },
    scrollBack(){
      this.$nextTick(() => {
        const yOffset = -100
        const el = document.querySelector(`#planning`)
        const y = el.getBoundingClientRect().top + window.pageYOffset + yOffset;
        window.scrollTo({
          top: y,
          behavior: "smooth",
        })
      })
    },
    openFlat(id){
      this.$router.push({name: 'flat', params: {id: id}}).catch(()=>{console.log()})
    }
  }
}