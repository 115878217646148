import {mapActions, mapGetters} from "vuex";
import {Carousel, Slide} from 'vue-carousel';


export default {
  name: "team",
  components: {
    Carousel,
    Slide
  },
  data() {
    return {
      currentImages: null,
    }
  },
  watch: {},
  created() {
    this.fetchTeam().then(()=>{
      this.currentImages = this.team[1]
    })
  },
  computed: {
    ...mapGetters({
      team: 'home/team',
      teamLoading: 'home/teamLoading',
    }),

  },
  methods: {
    ...mapActions({
      fetchTeam: 'home/GET_TEAM'
    }),
  }
}