import {mapActions, mapGetters} from "vuex";
import {Carousel, Slide} from 'vue-carousel';
import Vue from 'vue'
import {ObserveVisibility} from 'vue-observe-visibility'

Vue.directive('observe-visibility', ObserveVisibility)

export default {
  name: "media",
  components: {
    Carousel,
    Slide
  },
  data() {
    return {}
  },
  watch: {},
  created() {
    this.fetchProgress()
    this.fetchConstructionProgress()
  },
  computed: {
    ...mapGetters({
      progress: 'home/progress',
      progressLoading: 'home/progressLoading',
      constructionProgress: 'home/constructionProgress',
      constructionProgressLoading: 'home/constructionProgressLoading',
      variables: `setting/variables`,
    }),

  },
  methods: {
    ...mapActions({
      fetchProgress: 'home/GET_PROGRESS',
      fetchConstructionProgress: 'home/GET_CONSTRUCTION_PROGRESS',
    }),
    carouselNav($event, direction) {
      const carousel = this.$refs['media'];
      carousel.advancePage(direction);
    },
    getVariable(key) {
      return this.variables ? this.variables.find(el => el.key === key) ? this.variables.find(el => el.key === key).value : null : null
    }
  }
}