import {mapActions, mapGetters, mapMutations} from "vuex";
export default {
  name: "services",
  components:{
  },
  data(){
    return{

    }
  },
  created() {
    this.fetchServices()
  },
  computed:{
    ...mapGetters({
      services: 'home/services',
      servicesLoading: 'home/servicesLoading',
    }),

  },
  methods:{
    ...mapActions({
      fetchServices: 'home/GET_SERVICES',
    }),
  }
}