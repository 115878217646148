import {mapActions, mapGetters, mapMutations} from "vuex";
export default {
  name: "location",
  components:{
  },
  data(){
    return{

    }
  },
  created() {
    this.fetchMap()
  },
  computed:{
    ...mapGetters({
      map: 'home/map'
    }),
  },
  methods:{
    ...mapMutations({
    }),
    ...mapActions({
      fetchMap: 'home/GET_MAP'
    }),
    redirect(link){
        window.open(link, '_blank')
    }
  }
}