import {mapMutations} from "vuex";
export default {
  name: "about",
  components:{
  },
  data(){
    return{

    }
  },
  created() {
  },
  computed:{

  },
  methods:{
    ...mapMutations({
    }),
    scrollTo(id) {
      this.$nextTick(() => {
        const yOffset = -100
        const el = document.querySelector(`#${id}`)
        const y = el.getBoundingClientRect().top + window.pageYOffset + yOffset;
        window.scrollTo({
          top: y,
          behavior: "smooth",
        })
      })
    }
  }
}